/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
// require ("./src/scripts/jquery.min.js")
// require ("./src/scripts/jquery.pagepiling.min.js")
// You can delete this file if you're not using it

// import "./src/styles/style.min.css"
import "./src/styles/style.min0be4.css"
import helpers from './src/scripts/helpers';

if(typeof document === "undefined"){
    // var jsdom = require("jsdom"); 
    // var $ = require("jquery")(jsdom.jsdom().createWindow()); 
} else {
    var $ = require("jquery")
}

export const onPreRouteUpdate = ({ location, prevLocation }) => {
    $(".loader").fadeIn().delay(800).fadeOut();  
    helpers.initSmoothScroll()
}

export const onRouteUpdate = ({ location, prevLocation }) => {
    if(window && $("#wall").length > 0){
        helpers.initPagePilling()
    } else {
        helpers.unloadCSS("#pagepilling")
        $("body").css("overflow-y", "auto")
        $("body").css("scroll-behavior", "smooth")
        $("html").css("overflow-y", "hidden")
    }
}

export const onInitialClientRender = () => {
    $(document).on("click", ".hamburger", function() {
		$(this).toggleClass("hamburger--active");
		$(".page-head").toggleClass("page-head--open");
		if ($(".call-to-action") !== null) $(".call-to-action").toggleClass("call-to-action--alt");
	})
}